import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';

import { Layout, SEO, Grid, Box, Text } from 'components';
import { Intro, ProjectGridItem } from 'components/work';
import { colors } from 'styles/theme';

import { ProjectBasic } from 'fragments';

const ClientContainer = styled(Grid)`
  border-bottom: 1px solid ${colors.gray};

  &:last-child {
    border-bottom: 0;
  }
`;

const ClientTitle = Text.withComponent('h2');

const ClientSubtitle = styled(Text.withComponent('h3'))`
  margin-top: 0.6em;
`;

const WorkItem = styled(Box)`
  text-align: center;
  align-self: center;
`;

const Work = ({ data, location }) => {
  const page = data.prismic.allWorks.edges.slice(0, 1).pop().node;
  const interests = data.prismic.allInterest_tags.edges;

  const metaData = {
    title: page.meta_title ? RichText.asText(page.meta_title) : null,
    description: page.meta_description
      ? RichText.asText(page.meta_description)
      : null
  };

  if (page.meta_image) {
    metaData.image = page.meta_image.url;
    metaData.meta = [
      {
        property: `og:image:width`,
        content: page.meta_image.dimensions.width
      },
      {
        property: `og:image:height`,
        content: page.meta_image.dimensions.height
      }
    ];
  }

  const allClients = page.body;

  const [filter, setFilter] = useState({
    type: null,
    filter: null
  });


  useEffect(() => {
    if (location.hash) {
      const h = location.hash.split('/');

      setFilter({
        type: h[1],
        filter: h[2]
      });
    }
  }, [location.hash]);

  const updateFilter = (type = null, filter = null) => {
    setFilter({
      type: type,
      filter: filter
    });

    if (type && filter) {
      window.history.replaceState(null, null, `#/${type}/${filter}`);
    } else {
      window.history.replaceState(null, null, '#');
    }
  };

  return (
    <Layout>
      <SEO {...metaData} />

      <Intro
        updateFilter={updateFilter}
        filter={filter}
        interests={interests}
      />

      {allClients.map((c, clientIndex) => {
        const client = c.primary.client;

        let projects = c.fields;

        if (filter.type === 'type') {
          // Filter projects based on selected filter
          projects = projects.filter((project, i) => {
            const allTypes = project.projects.type;

            if (allTypes) {
              // Create array of only strings
              const types = allTypes.map((t, i) => {
                return RichText.asText(t.project_type.label).toLowerCase();
              });

              // See if filter matches list of strings
              return types.includes(filter.filter);
            }

            return false;
          });
        } else if (filter.type === 'interest') {
          let filterLabel;

          interests.forEach((interest, i) => {
            if (interest.node._meta.uid === filter.filter) {
              filterLabel = RichText.asText(interest.node.label);
            }
          });

          projects = projects.filter((project, i) => {
            const allInterests = project.projects.interest_tags;

            if (allInterests) {
              // Create array of only strings
              const interests = allInterests.map((interest, i) => {
                if (interest.interest) {
                  return RichText.asText(interest.interest.label);
                }

                return false;
              });

              // See if filter matches list of strings
              return interests.includes(filterLabel);
            }

            return false;
          });
        }

        if (projects.length) {
          return (
            <ClientContainer pt={{ _: 5, md: 7 }} key={clientIndex}>
              <Box gridColumn="span 12" pb={{ _: 5, md: 7 }}>
                <ClientTitle uppercase="true" textAlign="center">
                  {RichText.asText(client.name)}
                </ClientTitle>

                {client.subtitle && client.subtitle[0].text && (
                  <ClientSubtitle textAlign="center">
                    {RichText.asText(client.subtitle)}
                  </ClientSubtitle>
                )}
              </Box>

              {projects.map((project, projectIndex) => {
                project = project.projects;
                // Set column to center if it's the last item
                let projectColumn = ['1 / -1', 'span 6'];

                // If index is even and last, center the column
                if (
                  projectIndex === projects.length - 1 &&
                  projectIndex % 2 === 0
                ) {
                  projectColumn = ['1 / -1', '4 / span 6'];
                }

                return (
                  <WorkItem
                    gridColumn={projectColumn}
                    mb={[5, 7]}
                    key={projectIndex}
                  >
                    <ProjectGridItem project={project} />
                  </WorkItem>
                );
              })}
            </ClientContainer>
          );
        }

        return null;
      })}
    </Layout>
  );
};

Work.fragments = [ProjectBasic];

export default Work;

export const query = graphql`
  {
    prismic {
      allWorks {
        edges {
          node {
            meta_title
            meta_description
            meta_image
            intro_title
            body {
              ... on PRISMIC_WorkBodyClient {
                primary {
                  client {
                    ... on PRISMIC_Client {
                      name
                      subtitle
                      _meta {
                        uid
                        type
                      }
                    }
                  }
                }
                fields {
                  projects {
                    ...ProjectBasic
                  }
                }
              }
            }
          }
        }
      }
      allInterest_tags {
        edges {
          node {
            label
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;
