import React, { useState } from 'react';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';

import { colors, mediaQueries } from 'styles/theme';

import { Text, Box } from 'components';

const Container = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colors.gray};
`;

const FilterContainer = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
`;

FilterContainer.defaultProps = {
  py: 2,
  justifyContent: ['space-between', null, null, 'center']
};

const FilteredText = styled(Text)`
  text-transform: lowercase;
`;

FilteredText.defaultProps = {
  size: '2x',
  color: 'blue',
  textAlign: 'center'
};

const FilterLink = styled(Text)`
  cursor: pointer;
  color: ${props => (props.active ? colors.blue : colors.black)};

  &:hover {
    color: ${props => (props.active ? colors.blue : colors.blackDimmed)};
  }
`;

FilterLink.defaultProps = {
  mx: [1, 2]
};

const ShortLabel = Box.withComponent('span');
ShortLabel.defaultProps = {
  display: ['block', 'none']
};

const LongLabel = Box.withComponent('span');
LongLabel.defaultProps = {
  display: ['none', 'block']
};

const FilterInterestLink = styled(FilterLink)`
  text-align: right;

  ${mediaQueries.md} {
    position: absolute;
    right: 0;
  }
`;

const InterestContainer = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
  z-index: 10;
`;

const InterestContainerBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const InterestModal = styled(Box)`
  border-radius: 2rem;
`;

InterestModal.defaultProps = {
  bg: 'white',
  p: 5
};

const InterestListItem = styled(Text.withComponent('li'))`
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
  color: ${props => (props.active ? colors.blue : colors.black)};

  &:hover {
    color: ${props => (props.active ? colors.blue : colors.blackDimmed)};
  }
`;

export default props => {
  const [interestModalActive, setInterestModalActive] = useState(false);

  const getActiveState = (type, filter) => {
    if (props.filter.type === type && props.filter.filter === filter) {
      return 'true';
    }

    return null;
  };

  const getFilterCopy = filter => {
    let copy;

    if (filter === 'product') {
      return 'to use';
    } else if (filter === 'communication') {
      return 'to talk about';
    }

    props.interests.forEach((interest, i) => {
      if (filter === interest.node._meta.uid) {
        copy = RichText.asText(interest.node.label);
      }
    });

    return copy;
  };

  const setFilter = (type, filter) => {
    props.updateFilter(type, filter);
    setInterestModalActive(false);
  };

  return (
    <Container>
      <Box px={1}>
        <Text size="2x" textAlign="center">
          {props.filter.type === 'interest'
            ? 'Viewing projects filtered by'
            : 'Interesting things'}
        </Text>
        {props.filter.type ? (
          <FilteredText textAlign="center">
            {getFilterCopy(props.filter.filter)}
          </FilteredText>
        ) : null}
      </Box>

      <FilterContainer>
        <Box display="flex">
          <FilterLink
            onClick={() => {
              setFilter(null, null);
            }}
          >
            <LongLabel>All types</LongLabel>
            <ShortLabel>All</ShortLabel>
          </FilterLink>
          <FilterLink
            active={getActiveState('type', 'product')}
            onClick={() => {
              setFilter('type', 'product');
            }}
          >
            Product
          </FilterLink>
          <FilterLink
            active={getActiveState('type', 'communication')}
            onClick={() => {
              setFilter('type', 'communication');
            }}
          >
            <LongLabel>Communication</LongLabel>
            <ShortLabel>Comm</ShortLabel>
          </FilterLink>
        </Box>

        <FilterInterestLink
          onClick={() => setInterestModalActive(true)}
          color={props.filter.type === 'interest' ? 'blue' : 'black'}
        >
          {props.filter.type === 'interest'
            ? `↓ ${getFilterCopy(props.filter.filter)}`
            : '↓ Interests'}
        </FilterInterestLink>
      </FilterContainer>

      <InterestContainer display={interestModalActive ? 'flex' : 'none'}>
        <InterestContainerBg onClick={() => setInterestModalActive(false)} />
        <InterestModal>
          <ul>
            {props.interests.map((interest, i) => {
              return (
                <InterestListItem
                  active={getActiveState('interest', interest.node._meta.uid)}
                  onClick={() => {
                    setFilter('interest', interest.node._meta.uid);
                  }}
                  key={i}
                >
                  {RichText.asText(interest.node.label)}
                </InterestListItem>
              );
            })}
          </ul>
        </InterestModal>
      </InterestContainer>
    </Container>
  );
};
