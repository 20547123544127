import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from 'utils/linkResolver';
import { colors, mediaQueries } from 'styles/theme';

import Box from 'components/Box';
import Grid from 'components/Grid';
import Text from 'components/Text';

const Client = Text.withComponent('h3');
const Title = Text.withComponent('h2');

Title.defaultProps = {
  mt: [1, 0]
};

const Subtitle = styled(Title)`
  ${mediaQueries.sm} {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
`;

const ImageContainer = styled(Box)`
  overflow: hidden;
`;

ImageContainer.defaultProps = {
  gridColumn: ['2 / span 10', '2 / span 4']
};

const VideoPlayer = styled.video`
  display: block;
  position:relative;
  left:1px;
  width: calc(100% + 1px);
`;

const ImageHolder = styled.div``;

const ProjectLink = styled(Link)`
  text-decoration: none;

  &:hover {
    color: ${colors.blue};
    /* Image transition? Scale? */
  }
`;

const ProjectGridItem = ({ project, featured }) => {
  const videoRef = useRef();
  const [isPlaying, setPlaying] = useState(false);

  const p = project;

  // If it's featured we select the homepage image instead of work page one
  const projectImage = featured
    ? p.home_page_imageSharp || p.work_page_imageSharp
    : p.work_page_imageSharp || p.home_page_imageSharp;

  // If it's featured we select the homepage video instead of work page one
  const projectVideo = featured
    ? p.home_video || p.work_video
    : p.work_video || p.home_video;

  // Get the video URL, make sure it's a file and not a website link
  const videoUrl =
    projectVideo && projectVideo.__typename === 'PRISMIC__FileLink'
      ? projectVideo.url
      : null;

  // Set scroll listener when component is ready
  useEffect(() => {
    if (projectVideo) {
      window.addEventListener('scroll', onScroll);
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  // If it's a video item and we're on a touch screen, play and pause
  // the video based on scroll position
  const onScroll = () => {
    if (videoRef.current && 'ontouchstart' in window) {
      const y = videoRef.current.getBoundingClientRect().top;
      if (y > -75 && y < window.innerHeight * 0.4) {
        if (!isPlaying) {
          playVideo();
        }
      } else {
        pauseVideo();
      }
    }
  };

  // Play the video
  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .then(() => {
          setPlaying(true);
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  // Pause the video
  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setPlaying(false);
    }
  };

  // Check if image is landscape
  const isLandscape =
    projectImage && projectImage.childImageSharp.fluid.aspectRatio > 1;

  // Render the image and video elements
  const renderImageAndVideo = (image, video) => {
    if (video) {
      return (
        <VideoPlayer
          src={video}
          poster={image.childImageSharp.fluid.src}
          muted
          loop
          preload="auto"
          playsInline
          ref={videoRef}
        />
      );
    } else if (image) {
      return (
        <ImageHolder>
          <Img fluid={image.childImageSharp.fluid} alt="" />
        </ImageHolder>
      );
    }

    return null;
  };

  return (
    <ProjectLink
      to={linkResolver(project._meta)}
      onMouseEnter={playVideo}
      onMouseLeave={pauseVideo}
    >
      <Grid gridTemplateColumns={['repeat(12, 1fr)', 'repeat(6, 1fr)']} px={0}>
        <ImageContainer gridColumn={isLandscape ? '1 / -1' : undefined}>
          {renderImageAndVideo(projectImage, videoUrl)}
        </ImageContainer>

        <Box gridColumn={['1 / -1', '2 / -2']} pt={3} pb={2} textAlign="center">
          {featured ? (
            <>
              <Client uppercase="true">
                {RichText.asText(project.client.name)}
              </Client>

              {project.subtitle ? (
                <Subtitle>{RichText.asText(project.subtitle)}</Subtitle>
              ) : null}
            </>
          ) : (
            <>
              {project.title ? (
                <Title>{RichText.asText(project.title)}</Title>
              ) : null}
            </>
          )}
        </Box>
      </Grid>
    </ProjectLink>
  );
};

ProjectGridItem.defaultProps = {
  project: {},
  featured: false
};

export default ProjectGridItem;
